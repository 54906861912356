import guitar from './guitar.jpeg';
import './App.css';

function App() {
  
  return (
    <div className="App">
      
      <div className="App-title">
        <header className="App-content">
          MARK LEWIS
        </header>  
      </div>
      
      <div className="App-image">
        <img className="App-content" src={guitar} alt="Mark plays guitar" />
      </div>
      

    </div>
  );
}

export default App;
